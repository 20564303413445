import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import GatsbyImage from 'gatsby-image';
import {
  Layout,
  SEO,
  Hero,
  Banner,
  HeadingWithCopy,
  OurTeam,
  Contact,
} from '../components';
import { LogoTextOnly } from '../components/vectors';
import config from '../utils/config';

function IndexPage() {
  const {
    heroImage,
    heroForegroundImage,
    banner1,
    banner2,
  } = useStaticQuery(graphql`
    {
      heroImage: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroForegroundImage: file(relativePath: { eq: "hero-foreground.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner1: file(relativePath: { eq: "banner-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      banner2: file(relativePath: { eq: "banner-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Home" />
      <HomeHero
        background={heroImage.childImageSharp.fluid}
        foreground={heroForegroundImage.childImageSharp.fluid}
      />
      <LandingPartOne />
      <Banner
        image={banner1.childImageSharp.fluid}
        imgStyle={{ objectPosition: 'top' }}
      />
      <LandingPartTwo />
      <News />
      <OurTeam />
      <Banner
        image={banner2.childImageSharp.fluid}
        imgStyle={{ objectPosition: 'top' }}
      />
      <Contact />
    </Layout>
  );
}

function HomeHero({ background, foreground }) {
  return (
    <Hero image={background} aspectClassNames="aspect-w-16 aspect-h-9">
      <LogoTextOnly
        aria-label={`${config.title} — Since 1970`}
        className="absolute inset-x-0 z-10 w-full max-w-xl px-4 mx-auto xl:max-w-2xl sm:px-6 lg:px-8 bottom-1/5 text-yellow lg:z-0"
      />
      <div className="absolute inset-0 flex">
        <GatsbyImage
          fluid={foreground}
          className="flex-1 pointer-events-none"
        />
      </div>
    </Hero>
  );
}

function LandingPartOne() {
  return (
    <HeadingWithCopy
      heading="Providing Global Emergency Service Solutions"
      cta={{
        slug: '/what-we-do/',
        label: 'Discover what we do',
      }}
    >
      <p>
        R&M Aviation is a leading provider of aerial fire suppression services
        and has been delivering excellence in aviation for over 50 years.
      </p>
      <p>
        Our longevity in our specialist field means we bring extensive
        experience and knowledge to every operation, while our innovative spirit
        ensures we remain at the forefront of the aerial firefighting industry.
      </p>
      <p>
        As a result, R&M Aviation has become a trusted partner to government
        agencies and forestry companies in Australia and internationally.
      </p>
      <p>
        With an unwavering commitment to protect land, nature and people, we
        pride ourselves on providing the highest standards of aerial
        firefighting services to support our clients.
      </p>
    </HeadingWithCopy>
  );
}

function LandingPartTwo() {
  return (
    <HeadingWithCopy
      heading="Mission and values"
      cta={{
        slug: '/company-profile/',
        label: 'Find out more about our company',
      }}
    >
      <h2>Our Mission</h2>
      <p>
        To be trusted and relied upon to support and protect from the air.
        Delivering aviation excellence in the protection of land, nature, and
        people
      </p>
      <h2>Our Values</h2>
      <p>As a company, R&M Aviation is committed to:</p>
      <ul>
        <li>Exceeding expectations</li>
        <li>Being accountable - for actions, decisions, and results</li>
        <li>Forming collaborative partnerships</li>
        <li>Acting with integrity</li>
        <li>
          Prioritising safety and protection - of ourselves, others, and the
          environment
        </li>
      </ul>
    </HeadingWithCopy>
  );
}

function News() {
  const videos = [
    {
      src: 'https://www.youtube.com/embed/PhAjMwQr-jk',
    },
    {
      src: 'https://www.youtube.com/embed/H1n_Yhz4YZI',
    },
    {
      src: 'https://www.youtube.com/embed/P9HiRVwIxBw',
    },
    {
      src: 'https://www.youtube.com/embed/jBO95tRCB2c',
    },
  ];

  return (
    <article className="relative py-px">
      <div className="w-full px-8 py-24 mx-auto bg-black md:py-36 max-w-screen-2xl">
        <h2 className="text-center heading-2">News</h2>
        <div className="relative grid max-w-5xl gap-12 mx-auto mt-8 mb-32 md:grid-cols-2">
          {videos.map((video) => (
            <div
              key={video.src}
              className="relative h-0 aspect-w-16 aspect-h-9"
            >
              <iframe
                src={video.src}
                frameBorder={0}
                allowFullScreen
                aria-hidden={false}
                title="Embedded video"
                className="absolute inset-0 w-full h-full"
              />
            </div>
          ))}
        </div>
        {/* <div className="flex justify-center mt-8">
          <Link to="/" className="button bg-teal">
            See more news
          </Link>
        </div> */}
        <svg
          className="absolute inset-x-0 top-0 block w-full text-white h-14 md:h-28"
          fill="currentColor"
          viewBox="0 0 1 1"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="0,0 1,0 0,1" />
        </svg>
        <svg
          className="absolute inset-x-0 bottom-0 block w-full text-white h-14 md:h-28"
          fill="currentColor"
          viewBox="0 0 1 1"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="0,1 1,0 1,1" />
        </svg>
      </div>
    </article>
  );
}

export default IndexPage;
